.header {
  position: fixed;
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  color: #fff;
  background: #fff;
}

.header-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-wrapper header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
}

.header-wrapper header a {
  display: flex;
  height: 34px;
  align-items: center;
  border: 0;
  color: #fff;
  flex-flow: row nowrap;
}

.header-wrapper header img {
  height: 100%;
  margin-right: 10px;
}

.header-wrapper header h2 {
  display: block;
  position: relative;
  line-height: 18px;
  margin: 0;
  color: #fff;
  font-size: 1.25em;
  white-space: nowrap;
}

.nav-wrapper {
  position: relative;
  height: 34px;
  margin-left: auto;
}

.nav-wrapper nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  box-sizing: border-box;
}

@media only screen and (min-width: 1024px) {
  .nav-wrapper nav {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
    height: auto;
    background: none;
  }
}

.nav-wrapper nav ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin-top: 50px;
  padding: 0;
  width: 100%;
  background: #61dafb;
  box-sizing: border-box;
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .nav-wrapper nav ul {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;
    width: auto;
    background: none;
  }
}

.nav-wrapper nav ul li {
  flex: 1 1 auto;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.nav-wrapper nav ul li a {
  display: flex;
  margin: 0;
  padding: 10px;
  align-items: center;
  box-sizing: border-box;
  color: inherit;
  font-size: 0.9em;
  height: 50px;
  justify-content: center;
  transition: background-color 0.3s;
}

.nav-wrapper nav ul li a:hover {
  color: #ffffff;
}

@media only screen and (min-width: 1024px) {
  .nav-wrapper nav ul li a {
    display: flex;
    margin: 0;
    padding: 6px 10px;
    height: 32px;
    line-height: 1.2em;
    border: 0;
    color: hsla(0, 0%, 100%, 0.8);
    font-size: 1em;
    font-weight: 300;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.content_form {
  max-width: 400px;
  width: 100%;
}

.content_bg {
  margin: -20px;
  z-index: -9;
  position: fixed;
  height: calc(100vh + 40px);
  width: calc(100vw + 40px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),
    url("../../assets/images/bg.jpg");

  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url("../../assets/images/bg.jpg");

  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0.6)),
      color-stop(100%, rgba(0, 0, 0, 0.6))
    ),
    url("../../assets/images/bg.jpg");

  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url("../../assets/images/bg.jpg");

  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url("../../assets/images/bg.jpg");

  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url("../../assets/images/bg.jpg");

  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    url("../../assets/images/bg.jpg");
}

.login-form {
  padding: 20px;
  margin: 20px;
  background-color: rgb(232, 232, 232);
  background-color: rgba(232, 232, 232, 0.8);
  border-radius: 3px;
}

.login-form h3 {
  height: 20px;
  line-height: 20px;
  padding: 0 0 35px 0;
  text-align: center;
  font: 20px "microsoft yahei", Helvetica, Tahoma, Arial, "Microsoft jhengHei", sans-serif;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  font-size: 12px;
  line-height: 70px;
  color: #999;
  text-align: center;
  clear: both;
}
