.ant-drawer-body {
  padding: 0px !important;
}

.ant-form-item {
  margin-bottom: 5px;
}

.ant-form-item-label {
  padding: 0 0 2px !important;
}

.virtual-table-cell {
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: -1px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fixo {
  background-color: rgb(149, 159, 167);
  width: calc(100% + 25px);
  padding: 5px 5px 5px 12px;
  display: flex;
  margin: -20px 0px 0px -12p;
  display: "flex";
  overflow-x: auto;
}

.fixo > * {
  margin-right: 5px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.w-100 {
  width: 100% !important;
}

.mt-8 {
  margin-top: 8px;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.dp-menu-area {
  display: flex;
  margin: -6px -12px;
  padding: 6px 12px;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.font-sz-80 {
  font-size: 80%;
}

.font-sz-70 {
  font-size: 70%;
}

.nowrap {
  white-space: nowrap;
}

.font-bold {
  font-weight: bold;
}

.div-acoes {
  display: none;
  position: absolute;
  z-index: 99999;
  top: 58px;
  left: 10%;
  padding: 6px 10px 10px 10px;
  background-color: aliceblue;
  border: 1px solid #e8e9e8;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
}

.mt-23 {
  margin-top: 23px;
}

.mb-23 {
  margin-bottom: 23px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.p-5 {
  padding: 5px;
}

.box-shadow {
  box-shadow: rgb(149, 159, 166) 0px 0px 5px 0px;
}

.sub-drawer-1 {
  z-index: 1001 !important;
}

.sub-drawer-1 .ant-drawer-content-wrapper {
  max-width: 980px;
}

.in-line {
  display: flex;
  align-items: center;
}

.p-0 {
  padding: 0;
}

.pr-15 {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

/*HEADER*/

.header-wrapper {
  position: relative !important;
  padding: 0 !important;
  height: 65px !important;
  background: var(--component_bg) !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-layout-header {
  line-height: 45px;
}

.header-collapsed {
  padding: 10px 20px;
  height: 45px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-collapsed:hover,
.header-dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.header-search {
  display: flex;
  width: 100%;
  max-width: 300px;
}

.header-user-info {
  float: right;
  margin-right: 20px;
  display: flex;
}

.header-dropdown-link {
  display: inline-block;
  padding: 0 12px;
  height: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  cursor: pointer;
}

.header-dropdown-link i:first-child {
  font-size: 20px !important;
}

.badge sup {
  height: 14px;
  line-height: 14px;
  padding: 0 5px;
  right: -6px;
  top: -6px;
  font-size: 11px;
}

.header-menu {
  background-color: #fff;
  box-shadow: 1px 1px 13px 0px #959fa6;
}

.header-menu-notificacao {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-menu-notificacao .notificacao-item {
  cursor: pointer;
  padding: 0px;
}

.header-menu-notificacao .notificacao-item > :hover {
  background-color: var(--primary_1) !important;
}

.notificacao-datas {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

/*END HEADER*/

.logo {
  position: relative;
  padding-left: 14px;
  height: 65px;
  line-height: 65px;
  transition: all 0.3s;
  background: #eff1f4;
  overflow: hidden;
}

.logo img {
  display: inline-block;
  margin-top: 15px;
  height: 36px;
}

.logo h1 {
  display: inline-block;
  margin: 0;
  color: #fff;
  vertical-align: middle;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

/*DOCUMENTS*/

.list-file {
  min-height: 125px;
  overflow: auto;
  align-content: flex-start;
  user-select: none;
}

.file {
  border-radius: 4px;
  padding: 10px;
  justify-content: flex-start;
  height: 130px;
}

.file-list {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.file i {
  font-size: 50px;
  height: 50px;
}

.file-check {
  position: absolute;
  z-index: 1;
}

.file-check .ant-checkbox {
  padding: 10px;
}

.file-icon {
  text-align: center;
  height: 70px;
  font-size: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.file-description {
  display: flex;
  flex-direction: column;
}

.file:hover {
  background-color: var(--primary_1) !important;
  cursor: pointer;
}

.file-details:hover {
  margin-right: -70px;
  width: 270px !important;
  background-color: #fff;
  transform: translateX(-70px);
  transition: ease 0.1s transform;
}

.file:hover .file-icon {
  transform: scale(1.09);
}

.selected-file,
.selected-file:hover {
  background-color: var(--primary_2) !important;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 70vh;
  max-height: 540px;
  background: #002240;
  overflow: hidden;
}

.ant-carousel .slick-slide div {
  color: #fff;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.move-carousel {
  display: flex;
  justify-content: center;
  padding: 12px;
}

.move-carousel button {
  margin: 0 6px;
}

/*END DOCUMENTS*/
