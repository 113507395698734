.content-wrapper {
  min-height: 100vh !important;
}

.header {
  padding: 0 !important;
  width: 100%;
}

.content {
  padding-top: 0;
  margin: 0px;
  padding: 15px;
  height: calc(100vh - 98px);
  overflow-y: auto;
  background-color: #fff;
}
