.events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }
  
  .calendario-status-fonte .ant-badge-status-text {
    font-size: 12px !important;
  }
  
  .evento-calendario-item {
    padding-top: 5px;
  }
  .evento-calendario-item:hover {
    background-color: #a2d4ff;
    border-radius: 2px;
  }
  
  .ant-fullcalendar-value {
    background: #d3edfe !important;
    font-weight: bold;
    padding-right: 3px;
  }
  
  .list-items-calendar ul.ant-list-items {
    margin-left: -8px;
    margin-right: -8px;
  }
  .list-items-calendar li.ant-list-item {
    padding-left: 8px;
    padding-right: 8px;
  }
  .list-items-calendar .ant-list-item-meta {
    margin-bottom: 0;
  }
  .list-items-calendar .ant-list-item-meta-title {
    margin-bottom: 0;
  }
  .list-items-calendar .ant-list-item-action {
    margin-top: 0;
  }
  
  .list-item-hover:hover {
    background-color: #e6f7ff;
  }